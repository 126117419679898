<template>
  <section class="py_100">
    <div class="d-flex align-items-center justify-content-center h-100 py_100">
      <div class="page_not_found text-center h-100 w-50 d-none">
        <h1 class="text-danger">SSO Login</h1>
        <h2 class="m-4 text-uppercase">Please Wait!</h2>
        <p>You are going to be authenticated</p>
        <div
          class="separator transparent center"
          style="margin-top: 35px"
        ></div>
      </div>
    </div>
    <!-- Recommended Videos View Pop-Up -->
    <clientPopup
      v-show="isModalVisible"
      @close="removeUser"
      :all-clients="this.clientsData"
      :sso-status="this.ssoStatus"
      @closeModal="isModalVisible = $event"
    />
  </section>
</template>
<script>
// import $ from 'jquery';
import "bootstrap";
import AuthService from "@/services/AuthService";
import clientPopup from "../components/Clientspopup.vue";
import commonFunction from '../mixin/commonFunction'

export default {
  name: "sso-select-client",
  mixins: [commonFunction],
  components: {
    clientPopup,
  },
  data() {
    return {
      clientsData: [],
      ssoStatus: false,
      isModalVisible: false,
    };
  },
  mounted() {
    window.localStorage.setItem("userToken", this.$route.params.token);
    this.getAuthUser();
    if (this.$route.params.multidomain && this.$route.params.multidomain > 1) {
      this.checkDomain();
    }
  },
  methods: {
    getAuthUser() {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      AuthService.getAuthUser()
        .then((response) => {
          window.localStorage.setItem(
            "employeeEmail",
            response.data.user.email
          );
          window.localStorage.setItem(
            "user_profile",
            response.data.user.avatar
          );
          window.localStorage.setItem(
            "companyInformation",
            JSON.stringify(response.data.client)
          );
          //window.location.href = window.location.origin+'/user';
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          this.ssoStatus = response.data.user.sso_status;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          this.$toast.error(error.response.data.error, {
            position: "top-right",
          });
          this.$router.push({
            name: "login",
          });
        });
    },
    checkDomain() {
      if (localStorage.getItem("userToken")) {
      AuthService.userDomainCheck()
        .then((response) => {
          // let element = this.$refs.clientPopup.$el
          // $(element).modal('show');
          //this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          var decodedJson = this.decodeAPIResponse(response.data.data);
          this.clientsData = decodedJson.clients;
          // this.clientsData = response.data.clients;
          // console.log(response.data.clients);
          this.isModalVisible = true;
          //$('#clientPopup').modal('show');
        }).catch(({ response }) => {
            this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.$router.push({ name: "login" });
            }
          });
      } else {
        this.tokenExpired();
      }
    },

    /**  
     *  remove user and redirect to the Login page with message
     */
    removeUser() {
      if (localStorage.getItem("userToken")) {
      AuthService.removeUser()
        .then((response) => {
          if(response.data.delete == 1) {
            this.$toast.error(response.data.message, {
              position: "top-right",
            });
            this.$router.push({
              name: "login",
            });
          }else if(response.data.delete == 0) {
            this.$router.push({
              name: "user_home",
            });
          }
        }).catch(({ response }) => {
            this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.$router.push({ name: "login" });
            }
          });
      } else {
        this.tokenExpired();
      }
    },
    
  },
};
</script>
